@import "./_variables";
@import "~bootstrap/scss/bootstrap";
@import "direction-reveal/styles/direction-reveal.scss";
@import "slick-carousel/slick/slick.scss";
@import "slick-carousel/slick/slick-theme.scss";
@import "~font-awesome/scss/font-awesome";

html, body {
  position: relative;
  height: 100%;
  margin: 0; }

body #root {
  min-height: 100%;
  font-family: Arial;

  h1, h2, h3 {
    font-weight: bold; }
  h1 {
    font-size: 30px; }
  h2 {
    font-size: 24px; }
  h3 {
    font-size: 18px; }

  .allygn-container {
    max-width: 1200px; } }

#lightboxBackdrop {
  & > div {
    & > div {
      max-width: 4000px !important; } }
  button:active, button:hover, button:focus {
    outline: none; } }
