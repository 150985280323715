header#header {
  width: 100%;
  height: 100vh;
  padding: 0;
  background-image: url('../images/header-xs.jpg');
  background-color: #afadaa;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  #allygn-logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: calc(100% - 30px);
    max-width: 450px;
    img {
      width: 100%;
      height: auto; } }
  a.to-products {
    width: 100%;
    height: 54px;
    position: absolute;
    left: 0;
    bottom: 0;
    background-image: url('../images/scroll-down.svg');
    background-position: center;
    background-repeat: no-repeat; } }
