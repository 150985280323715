button.DisabledButton {
  width: 100%;
  color: white;
  background-color: rgb(150, 150, 150);
  border-radius: 0;
  padding: 12px 20px;
  &:hover {
    color: white; }
  &.onBottom {
    margin: auto;
    margin-top: 0;
    margin-bottom: 100px;
    display: block;
    max-width: 500px; } }

a.ShopButton {
  width: 100%;
  color: white;
  background-color: rgb(150, 150, 150);
  border-radius: 0;
  padding: 12px 20px;
  &.onBottom {
    margin: auto;
    margin-top: 0;
    margin-bottom: 100px;
    display: block;
    max-width: 500px; } }
