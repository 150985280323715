$fa-font-path: "~font-awesome/fonts";

$fern-blue: #336b95;
$fern-gray: #323232;

$allygn-blue: #336b95;
$allygn-gray: #323232;

$navbar-height: 54px;
$footer-height: 308px;
