section.AboutSection {
  position: relative;
  width: 100%;
  min-height: 100vh;
  padding: 74px 15px;
  background-position: center top;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-image: url('../images/paul-and-flo.jpg');
  .row {
    & > div {
      padding: 16px;
      p {
        color: white; }
      a {
        color: #336b95; } } } }
