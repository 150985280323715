.Specifications {
  max-width: 1200px;
  margin-bottom: 100px;
  .row {
    margin-left: 0;
    margin-right: 0;
    h2 {
      width: 100%;
      text-align: center;
      margin-top: 0;
      margin-bottom: 50px; } }
  table {
    width: 100%;
    display: none;
    td {
      padding-bottom: 15px;
      vertical-align: top;
      &:first-child {
        font-weight: bold;
        width: 250px;
        padding-right: 20px; } }
    @media (min-width: 575px) {
      display: block; } }
  .mobile-list {
    display: block;
    @media (min-width: 575px) {
      display: none; }
    & > div {
      margin-bottom: 35px;
      h3 {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 15px; } } } }
