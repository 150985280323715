.ProductItem {
  .wrapper {
    width: 100%;
    display: inline-block;
    position: relative;
    &:after {
      padding-top: 75%;
      display: block;
      content: ''; }
    .direction-reveal__overlay {
      background-color: rgba(0, 0, 0, 0.35); }
    a {
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      color: white;
      background-position: center;
      background-repeat: no-repeat;
      background-attachment: scroll;
      background-size: cover;
      .content {
        position: absolute;
        width: 100%;
        top: 50%;
        left: 50%;
        text-align: center;
        transform: translate(-50%, -50%);
        h2, h3 {
          text-transform: uppercase; }
        h2 {
          font-size: 24px;
          font-weight: bold;
          letter-spacing: .15em; }
        h3 {
          font-size: 16px;
          letter-spacing: .06em;
          font-weight: normal; }
        .separator {
          width: 24px;
          height: 4px;
          margin: 10px auto;
          background-color: white;
          transition: all 300ms ease 0s; } }
      .image-wrapper {
        width: 100%;
        height: 100%;
        background-color: #e7e5e6;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        img.item-image {
          width: 100%;
          height: auto; } } } } }
