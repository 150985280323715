section.Products {
  padding: 74px 0 59px 0;
  @media (min-width: 575px) {
    padding: 74px 15px 59px 15px; }
  h1 {
    width: 100%;
    padding: 50px 15px;
    text-align: center;
    color: #323232;
    text-transform: uppercase;
    font-size: 34px;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    @media (max-width: 575px) {
      margin-top: 0;
      margin-bottom: 22px; } } }
