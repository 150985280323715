.Description {
  max-width: 1200px;
  margin-bottom: 100px;
  .row {
    margin-left: -15px;
    margin-right: -15px;
    h1 {
      text-align: center;
      margin-bottom: 50px;
      margin-top: 50px;
      @media (min-width: 700px) {
        margin-top: 100px; } }
    .images {
      img.main-image {
        width: 100%;
        &:hover {
          cursor: pointer; } }
      .thumbs {
        margin-top: 5px;
        margin-left: -5px;
        margin-right: -5px;
        margin-bottom: 25px;
        .thumb {
          padding: 5px;
          img {
            width: 100%;
            &:hover {
              cursor: pointer; } } } } }
    span.price {
      display: block;
      margin-top: 25px;
      margin-bottom: 25px;
      font-weight: bold; }
    .description-text {
      margin-top: 10px;
      @media (min-width: 575px) {
        margin-top: -4px; } } } }
