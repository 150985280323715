@import "../styles/_variables";

nav#main-nav {
  min-height: $navbar-height;
  background-color: $fern-gray;
  text-transform: uppercase;
  .nav-brand {
    margin-top: -3px;
    display: inline-block;
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
    margin-right: 1rem;
    font-size: 1.25rem;
    line-height: inherit;
    white-space: nowrap; }
  .container {
    position: relative;
    max-width: 1200px;
    margin-top: 1px;
    padding-left: 15px !important;
    padding-right: 15px !important;
    @media (max-width: 575px) {
      padding-left: 0 !important;
      padding-right: 0 !important; } }
  .icons-wrapper {
    position: absolute;
    top: -1px;
    right: 48px;
    .icon-button {
      margin: 0 4px;
      width: 32px;
      height: 38px;
      line-height: 38px;
      font-size: 20px;
      color: white;
      display: inline-block;
      &:hover {
        cursor: pointer;
        color: $fern-blue; }
      &:last-child {
        margin-right: 0; } }
    @media (max-width: 575px) {
      right: 32px;
      .icon-button {
        margin: 0; } } }
  .navbar-toggler {
    color: white;
    border: none;
    padding-right: 0;
    &:focus {
      outline: none; } }
  .navbar-brand {
    margin-top: -3px; }
  #navbarResponsive {
    margin-right: 64px !important; }
  .navbar-nav {
    .nav-item {
      font-size: 12px;
      margin-right: 24px;
      &:hover {
        color: fade(white, 80%);
        outline: none;
        background-color: transparent; }
      &:active, &:focus {
        outline: none;
        background-color: transparent; } }
    .nav-link {
      color: white;
      &:hover {
        color: $fern-blue; }
      &:active {
        color: $fern-blue; }
      &.active {
        color: $fern-blue;
        outline: none; } } }
  @media (min-width: 992px) {
    transition: 0.3s;
    top: -$navbar-height;
    color: white;
    padding-top: 0;
    padding-bottom: 0;
    background-color: transparent;
    transition: background-color 0.3s ease-in-out;
    .icons-wrapper {
      right: 15px; }
    #navbarResponsive {
      margin-right: 0; }
    .navbar-brand > img {
      display: none;
      margin-top: 3px; }
    .nav-link {
      transition: none;
      color: white;
      &:hover {
        color: $fern-blue; }
      &:active {
        color: $fern-blue; } }
    &.navbar-shrink {
      transition: 0.3s;
      top: 0;
      background-color: $fern-gray;
      .navbar-brand > img {
        display: block; } } } }
