section.Contact {
  position: relative;
  width: 100%;
  min-height: 50vh;
  background-color: #eee;
  padding: 74px 0;
  @media (min-width: 575px) {
    padding: 74px 15px; }
  .row {
    margin-right: 0px;
    margin-left: 0px; }
  a {
    color: #336b95; } }
