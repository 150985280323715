.ProductsSkeleton {
  .item {
    width: 100%;
    display: inline-block;
    position: relative;
    background-color: #eee;
    margin-bottom: 28px;
    &:after {
      padding-top: 70%;
      display: block;
      content: ''; } } }
