.Features {
  max-width: 1200px;
  margin-bottom: 100px;
  .row {
    margin-left: 0;
    margin-right: 0; }
  h2 {
    width: 100%;
    text-align: center;
    margin-top: 0;
    margin-bottom: 50px; }
  h3 {
    text-align: center;
    margin-bottom: 25px; }
  p {
    text-align: justify;
    margin-bottom: 25px; } }
