footer.Footer {
  position: relative;
  width: 100%;
  background-color: #323232;
  text-align: center;
  color: white;
  font-size: 12px;
  .cookie-consent {
    position: fixed;
    bottom: 0;
    width: 100%;
    min-height: 44px;
    background-color: rgba(0, 0, 0, 0.8);
    color: #ccc;
    & > div {
      display: inline-block; }
    span {
      display: inline-block;
      padding: 13px;
      &:nth-child(2) {
        padding: 0 20px;
        margin-bottom: 10px;
        @media (max-width: 742px) {
          margin-top: 0px; } } }
    button {
      display: inline-block;
      margin-bottom: 10px;
      font-size: 11px;
      font-weight: bold;
      line-height: 20px;
      min-width: 26px;
      margin-right: 5px;
      padding: 2px 11px;
      cursor: pointer;
      text-align: center;
      white-space: nowrap;
      color: #FFFFFF;
      border: 0;
      outline: none;
      background-color: #336b95; } }
  .col-xs-12 {
    text-align: center;
    @media (min-width: 992px) {
      text-align: left; } }
  h3 {
    font-size: 12px;
    margin-top: 0;
    margin-bottom: 15px;
    color: #336b95;
    text-transform: uppercase;
    font-weight: bold; }
  a, a:focus, a:active, a:hover, a:visited {
    color: white; }
  img {
    width: 100%;
    height: 40px;
    margin: 50px auto; }
  .row {
    margin-bottom: 20px; }
  .copyright {
    width: 100%;
    background-color: #212529;
    min-height: 50px;
    padding: 16px;
    i {
      margin: 0 2px; } } }
