.LegalSection {
  position: relative;
  width: 100%;
  min-height: 100vh;
  padding: 74px 0;
  h1 {
    color: #336b95;
    font-size: 24px;
    font-weight: bold;
    text-transform: uppercase; }
  h2 {
    margin-top: 20px;
    color: #336b95;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase; } }
