.Product {
  .container {
    width: 100%;
    max-width: 1200px;
    margin-left: 0;
    margin-right: 0;
    margin: auto;
    margin-bottom: 100px;
    @media (min-width: 576px) {
      padding-left: 30px;
      padding-right: 30px; }
    @media (min-width: 1215px) {
      padding-left: 15px;
      padding-right: 15px; } }
  .product-header {
    width: 100%;
    height: 100px;
    @media (min-width: 300px) {
      height: 200px; }
    @media (min-width: 500px) {
      height: 300px; }
    @media (min-width: 700px) {
      height: 400px; }
    @media (min-width: 1200px) {
      height: 500px; }
    position: relative;
    background-color: #eee;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    & > div {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      &.xs {
        display: block; }
      &.sm {
        display: none;
        @media (min-width: 300px) {
          display: block; } }
      &.md {
        display: none;
        @media (min-width: 500px) {
          display: block; } }
      &.lg {
        display: none;
        @media (min-width: 700px) {
          display: block; } }
      &.xl {
        display: none;
        @media (min-width: 1200px) {
          display: block; } } } }
  .download-button-wrapper {
    padding: 15px;
    margin-top: -50px;
    margin-bottom: 20px;
    height: 100px;
    a.download-button {
      display: block;
      width: 100%;
      max-width: 300px;
      margin: auto;
      text-align: center;
      text-transform: uppercase;
      color: white;
      background-color: #969696;
      border-radius: 0;
      padding: 12px 20px;
      text-decoration: none;
      &:hover {
        color: #212529; } } }
  .allygn-container {
    padding: 15px;
    width: 100%;
    margin: auto; } }
