.ProductSkeleton {
  margin-bottom: 100px;
  .thumbs {
    margin-top: 5px;
    margin-left: -5px;
    margin-right: -5px; }
  .thumb {
    padding: 5px; }
  .header {
    height: 500px;
    background-color: #eee; }
  .h1 {
    height: 48px;
    width: 300px;
    background-color: #eee;
    margin: 50px auto; }
  .main-image {
    width: 100%;
    height: 300px;
    background-color: #eee;
    margin: auto; }
  .imgage {
    width: 100%;
    height: 72px;
    background-color: #eee;
    margin: auto; }
  .description {
    width: 100%;
    position: relative;
    .text {
      display: block;
      height: 20px;
      background-color: #eee;
      margin-bottom: 10px; } } }
